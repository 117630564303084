<template>
    <div class="popup__video-container">
        <div class="video__holder">
            <video class="popup__video" muted loop autoplay>
                <source :src="video" type="video/mp4">
            </video>
        </div>
    </div>
</template>

<script>
import { gsap } from 'gsap';
export default {

    props: {
        video: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            popupVideo: null
        };
    },

    mounted() {
        this.popupVideo = this.$el.querySelector('.popup__video');

        EventBus.$on('open-popup', () => {
            gsap.to('.popup__content', { opacity: 1, duration: 0.3 });
            this.popupVideo.addEventListener('canplay', () => {
                this.popupVideo.play();
            });
            this.popupVideo.load();
        });
    },

    methods: {
    }
};
</script>
