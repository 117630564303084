<template>
    <div>
        <div class="swiper swiper">
            <div class="swiper-wrapper">
                <div v-for="(image, index) in images" :key="index" class="swiper-slide">
                    <img :src="image.image" alt="" @click.prevent="handleClick(image.click)">
                </div>
            </div>
        </div>
        <div class="popup__navigation">
            <div class="popup__navigation-prev" @click.prevent="handlePrevSlide">
                <ChevronLeftIcon />
            </div>
            <div class="popup__navigation-next" @click.prevent="handleNextSlide">
                <ChevronRightIcon />
            </div>
        </div>

        <transition name="fade">
            <div v-show="internalPopup" class="popup light">
                <div class="popup__content">
                    <span @click.prevent="closeInternalPopup">
                        <CloseButton />
                    </span>
                    <img class="popup__image" :src="internalPopupImage" alt="">
                </div>
            </div>
        </transition>

    </div>
</template>

<script>
import { gsap } from 'gsap';
import Swiper, { Navigation, Pagination, Scrollbar } from 'swiper';

import ChevronLeftIcon from './ChevronLeftIcon.vue';
import ChevronRightIcon from './ChevronRightIcon.vue';
import CloseButton from './CloseButton.vue';

export default {
    props: {
        images: {
            type: Array,
            default: () => []
        }
    },

    components: {
        ChevronLeftIcon,
        ChevronRightIcon,
        CloseButton
    },

    data() {
        return {
            swiper: null,
            swiperEl: null,
            swiperSlides: null,
            currentSlideIndex: 0,
            internalPopup: false,
            internalPopupImage: ''
        };
    },

    mounted() {
        this.swiperEl = this.$el.querySelector('.swiper');
        this.swiperSlides = this.$el.querySelectorAll('.swiper-slide');

        this.initSwiper();

        EventBus.$on('open-popup', (slide) => {
            this.reInitSwiper(slide);
            gsap.delayedCall(0.1, () => {
                this.swiper.update();
                this.swiper.slideToLoop(slide, 0);
            });
            gsap.delayedCall(0.3, () => {
                gsap.to('.popup__content', { opacity: 1, duration: 0.3 });
            });
        });
    },

    methods: {
        initSwiper(slide) {
            if (this.swiper) {
                return;
            }

            this.swiper = new Swiper(this.swiperEl, {
                initialSlide: slide ?? 0,
                loop: true,
                slidesPerView: 1,
                spaceBetween: 0,
                touchRatio: 2,
                speed: 1000
            });

            this.swiper.on('realIndexChange', () => {
                this.currentSlideIndex = this.swiper.realIndex;
            });

            this.swiper.on('slideChangeTransitionEnd', (swiper) => {
                // if (this.swiper.realIndex === 0) {
                //     this.reInitSwiper();
                // }

                this.currentSlideIndex = swiper.realIndex;
            });
        },

        destroySwiper() {
            if (!this.swiper) {
                return;
            }

            this.swiper.destroy();
            this.swiper = null;
        },

        reInitSwiper(slide) {
            this.destroySwiper();
            this.initSwiper(slide);
        },

        handlePrevSlide() {
            if (!this.swiper) {
                return;
            }

            this.swiper.slidePrev();
        },

        handleNextSlide() {
            if (!this.swiper) {
                return;
            }

            this.swiper.slideNext();
        },

        handleClick(image) {
            if (image) {
                this.internalPopupImage = image;
                this.internalPopup = true;
            }
        },

        closeInternalPopup() {
            this.internalPopup = false;
        }
    }
};
</script>
