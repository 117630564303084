<template>
    <div>
        <img class="popup__image" :src="image" alt="">
    </div>
</template>

<script>
import { gsap } from 'gsap';

export default {

    props: {
        image: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
        };
    },

    mounted() {
        EventBus.$on('open-popup', (slide) => {
            gsap.to('.popup__content', { opacity: 1, duration: 0.3 });
        });
    }
};
</script>