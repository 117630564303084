<template>
    <div>
        <img v-if="section === 'offgrid'" class="popup__iframe-image" :src="'./media/images/iframe.webp'" alt="">
        <img v-if="section === 'marine'" class="popup__iframe-image" :src="'./media/images/marine/iframe.webp'" alt="">
        <iframe src="https://vrm.victronenergy.com/installation-overview" class="popup__iframe" />
        <!-- <div v-if="section === 'marine'" class="popup__image--iframe-back" @click="backToSlide()"></div> -->
    </div>
</template>

<script>
import { gsap } from 'gsap';

export default {

    props: {
        section: {
            type: String,
            default: 'offgrid'
        }
    },

    data() {
        return {
        };
    },

    mounted() {
        EventBus.$on('open-popup', (slide) => {
            gsap.to('.popup__content', { opacity: 1, duration: 0.3 });
        });
    },

    methods: {
        backToSlide() {
            this.$parent.closePopupIframe();
        }
    }
};
</script>
